import baseState from '../store/baseState';
import { FORGOT_PASSWORD, RESET_PASSWORD } from '../actions/action';
export default (state = baseState.passwordData, { payload, type, error }) => {
    switch (type) {
        case FORGOT_PASSWORD.REQUEST:
            return {
                ...state
            };
        case FORGOT_PASSWORD.SUCCESS:
            return {
                ...state,
                forgottenPasswordData: payload.data
            };
        case FORGOT_PASSWORD.FAILURE:
            return {
                ...state,
                forgottenPasswordData: {},
                error: error.message
            };
        case RESET_PASSWORD.REQUEST:
            return {
                ...state
            };
        case RESET_PASSWORD.SUCCESS:
            return {
                ...state,
                resetPasswordData: payload.data
            };
        case RESET_PASSWORD.FAILURE:
            return {
                ...state,
                resetPasswordData: {},
                error: error.message
            };
        default:
            return state;
    }
};