import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "./common/Spinner";

const Login = lazy(() => import("./user/Login"));
const AppsGrid = lazy(() => import("./appsGrid/AppsGrid"));
const ForgotPassword = lazy(() => import("./forgotPassword/ForgotPassword"));
const NewPassword = lazy(() => import("./forgotPassword/NewPassword"));

class AppRoutes extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/apps" component={AppsGrid} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/resetPassword" component={NewPassword} />
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
