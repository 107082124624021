import { combineReducers } from 'redux';
import authData from './authentication';

import machineCutting from './machineCutting';
import myAppsData from './myApps';
import passwordData from './password'

export default function createReducer() {
    return combineReducers({
        authData,
        machineCutting,
        myAppsData,
        passwordData
    });
}