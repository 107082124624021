import baseState from '../store/baseState';
import { GET_MY_APPS, CREATE_SESSION, RETRIEVE_TOKEN } from '../actions/action';
export default (state = baseState.myAppsData, { payload, type, error }) => {
    switch (type) {
        case GET_MY_APPS.REQUEST:
            return {
                ...state
            };
        case GET_MY_APPS.SUCCESS:
            return {
                ...state,
                allMyApps: payload.data
            };
        case GET_MY_APPS.FAILURE:
            return {
                ...state,
                allMyApps: [],
                error: error.message
            };
        case CREATE_SESSION.REQUEST:
            return {
                ...state
            };
        case CREATE_SESSION.SUCCESS:
            return {
                ...state,
                sessionCreationData: payload.data
            };
        case CREATE_SESSION.FAILURE:
            return {
                ...state,
                sessionCreationData: {},
                error: error.message
            };
        case RETRIEVE_TOKEN.REQUEST:
            return {
                ...state
            };
        case RETRIEVE_TOKEN.SUCCESS:
            return {
                ...state,
                retrievedData: payload.data
            };
        case RETRIEVE_TOKEN.FAILURE:
            return {
                ...state,
                retrievedData: {},
                error: error.message
            };
        default:
            return state;
    }
};