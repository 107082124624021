import React from 'react';
import { Nav, Button, Navbar, Image, Container, Dropdown } from 'react-bootstrap';
import '../../assets/NavBar.css';
import masterlogo from '../../assets/images/agriLogo.jpg';

class NavBar extends React.Component {
  state = {
    companyInfo: {}
  }

  componentDidMount() {
    let companyInfo = localStorage.getItem('companyInfo');
    this.setState({ companyInfo: JSON.parse(companyInfo) })
  }
  userLogout = () => {
    window.localStorage.clear();
    this.setState({ companyInfo: {} })
  }

  render() {
    return (
      <>
        <Navbar className='py-0 bg-white mb-1 shadow' sticky='top' collapseOnSelect >
          <Container fluid>
            <Navbar.Brand href="#" className='p-0'>
              <Image width="80px" src={masterlogo} alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                {/* <Nav.Link className='pe-0' href="/home">
                  <Button className='rounded-0 shadow-none fw-bold bg-light-blue text-uppercase'>Dashboard</Button>
                </Nav.Link> */}
                <Dropdown className='p-2 pe-0' align="end" >
                  <Dropdown.Toggle className='rounded-0 shadow-none border-0 bg-light text-dark fw-bold py-2 text-uppercase' id="dropdown-basic">
                    {this.state.companyInfo != undefined ? this.state.companyInfo.companyName : 'Company'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu id="dropdown-menu-align-end">
                    <Dropdown.Item href="/login" onClick={this.userLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default NavBar;