export default {
  authData: {
    loginDetails: {},
    authenticated: false,
    error: ''
  },
  machineCutting: {
    searchCutting: {},
    addCutting: {},
    updatedCutting: {},
    deleteCutting: {},
    error: ''
  },
  myAppsData: {
    allMyApps: [],
    sessionCreationData: {},
    retrievedData: {},
    error: ''
  },
  passwordData: {
    forgottenPasswordData: {},
    resetPasswordData: {},
    error: ''
  }
}